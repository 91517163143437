<template>
  <el-table-column :prop="prop" :label="label">
    <template slot-scope="scope">
      <span :style="childStyles(scope.row)">
        {{ scope.row[prop] }}
      </span>
    </template>
  </el-table-column>
</template>

<script>
import isArray from "lodash/isArray"
export default {
  name: "table-tree-column",
  props: {
    prop: {
      type: String
    },
    label:{
      type:String
    },
    treeKey: {
      type: String,
      default: "id"
    },
    levelKey: {
      type: String,
      default: "level"
    },
    childKey: {
      type: String,
      default: "children"
    }
  },
  methods: {
    childStyles(row) {
      return { "padding-left": (row[this.levelKey] * 25) + "px" }
    },
    iconClasses(row) {
      return [!row._expanded ? "el-icon-caret-right" : "el-icon-caret-bottom"]
    },
    iconStyles(row) {
      return { visibility: this.hasChild(row) ? "visible" : "hidden" }
    },
    hasChild(row) {
      return (isArray(row[this.childKey]) && row[this.childKey].length >= 1) || false
    }
  }
}
</script>
