<template>
  <div class="menuManagement">
    <el-form>
      <el-form-item>
        <el-button type="primary" @click="addMenu" class="el-button_add">
          <i class="el-icon-plus" /> 新增
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      v-loading="loading"
      :data="menuManagementTable"
      row-key="id"
      lazy
      border
      :header-cell-style="cellStyle"
      height="652"
    >
      <el-table-column prop="id" label="ID" width="100" align="center" />
      <TableTreeColumn prop="name" treeKey="id" label="名称" align="center" />
      <el-table-column prop="icon" label="图标" align="center">
        <template v-slot="{ row }">
          <i :class="row.icon || ''"></i>
        </template>
      </el-table-column>
      <el-table-column prop="menuType" label="类型" align="center">
        <template v-slot="{ row }">
          <el-tag v-if="row.menuType == 'DIRECTORY'" size="small">目录</el-tag>
          <el-tag v-else-if="row.menuType == 'MENU'" size="small" type="success"
            >菜单</el-tag
          >
          <el-tag v-else-if="row.menuType == 'RIGHT'" size="small" type="info"
            >按钮</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="parentName" label="上级菜单" align="center" />
      <el-table-column prop="url" label="菜单URL" align="center" />
      <el-table-column prop="perms" label="授权标识" align="center" />
      <el-table-column prop="orderNum" label="排序" align="center" />
      <el-table-column fixed="right" label="操作" width="200" align="center">
        <template v-slot="{ row }">
          <div class="operation_bottom">
            <DicButton
              @buttonClick="editItem(row)"
              icon="icon-jr-icon-editt iconfont"
              text="编辑"
            />
            <DicButton
              @buttonClick="deleteItem(row)"
              icon="icon-jr-icon-delete_1 iconfont"
              text="删除"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      ref="addVisible"
      :title="dialogType ? '编辑菜单' : '新增菜单'"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
      @close="handleClose"
    >
      <el-form
        ref="menuManagementObj"
        :model="menuManagementObj"
        :rules="menuManagementRules"
        label-width="80px"
      >
        <el-form-item label="菜单类型" prop="menuType">
          <el-radio-group v-model="menuManagementObj.menuType">
            <el-radio
              v-for="item in menuTypeList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="`${menuTypeList[menuName].label}名称`"
          prop="name"
        >
          <el-input
            v-model="menuManagementObj.name"
            :placeholder="`请输入${menuTypeList[menuName].label}名称`"
          />
        </el-form-item>
        <el-form-item label="上级菜单" prop="parentName">
          <PopupTreeInput
            :data="popupTreeData"
            :props="popupTreeProps"
            :prop="
              menuManagementObj.parentName == null ||
              menuManagementObj.parentName == ''
                ? '顶级菜单'
                : menuManagementObj.parentName
            "
            :nodeKey="'' + menuManagementObj.parentId"
            :currentChangeHandle="handleTreeSelectChange"
          ></PopupTreeInput>
        </el-form-item>
        <el-form-item label="授权标识" prop="perms">
          <el-input
            v-model="menuManagementObj.perms"
            placeholder="请输入授权标识"
          />
        </el-form-item>
        <el-form-item label="菜单URL" prop="url">
          <el-input
            v-model="menuManagementObj.url"
            placeholder="请输入菜单URL"
          />
        </el-form-item>
        <el-form-item label="排序编号" prop="orderNum">
          <el-input-number
            v-model="menuManagementObj.orderNum"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="菜单图标" prop="icon">
          <el-input
            v-model="menuManagementObj.icon"
            placeholder="请输入菜单icon"
          />
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  obtainMenuList,
  addMenuList,
  updataMenuList,
  deleteMenuList,
} from "@/api/menuList";
import PopupTreeInput from "@/components/PopupTreeInput";
import TableTreeColumn from "@/components/TableTreeColumn";
import DicButton from "@/components/DicButton";
const defaultMenuManagementObj = Object.freeze({
  id: null, //ID
  name: null, //名称
  icon: null, // 图标
  menuType: "DIRECTORY", // 类型
  parentName: "", // 上级菜单
  url: null, // 菜单URL
  perms: null, // 授权标识
  orderNum: null, // 排序
  parentId: null,
});

export default {
  name: "Menu",
  components: {
    PopupTreeInput,
    TableTreeColumn,
    DicButton,
  },

  data() {
    return {
      queryInfo: {
        templateName: null, // 菜单名称
      },
      menuManagementTable: [], //表格数据
      dialogType: 0, // 面板类型  0 新增 1 编辑
      dialogVisible: false, // 新增编辑的弹窗
      menuManagementObj: { ...defaultMenuManagementObj },
      menuManagementRules: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
      },
      menuTypeList: [
        { label: "目录", value: "DIRECTORY" },
        { label: "菜单", value: "MENU" },
        { label: "按钮", value: "RIGHT" },
      ],
      popupTreeData: [],
      popupTreeProps: {
        label: "name",
        children: "children",
      },
      loading: false, // 表格loadin动画
      cellStyle: {
        background: "#EBEAEA",
        color: "#151515",
      },
    };
  },

  computed: {
    menuName() {
      return this.menuTypeList.findIndex((item) => {
        return item.value === this.menuManagementObj.menuType;
      });
    },
  },

  created() {
    this.search();
  },

  methods: {
    async search() {
      this.loading = true;

      const { data: res } = await obtainMenuList();

      if (res.success) {
        this.tableTreeData = res.data;

        this.popupTreeData = this.getParentMenuTree(res.data);

        this.loading = false;
      }

      this.menuManagementTable = res.data;

      this.loading = false;
    },
    // 获取上级菜单树
    getParentMenuTree(tableTreeData) {
      let parent = {
        parentId: 0,
        name: "顶级菜单",
        children: tableTreeData,
      };
      return [parent];
    },
    // 新增
    addMenu() {
      this.dialogVisible = true;

      this.dialogType = 0;
    },
    // 编辑
    editItem(row) {
      Object.assign(this.menuManagementObj, row);

      if (!row.children.length) {
        this.menuManagementTable.forEach((item) => {
          item.children.forEach((el) => {
            if (row.parentId === item.id) {
              this.menuManagementObj.parentName = item.name;
            }
          });
        });
      }

      this.dialogVisible = true;

      this.dialogType = 1;
    },
    // 删除
    deleteItem(row) {
      this.$confirm(`确认删除${row.name}菜单吗 ?`, "提示", {})
        .then(async () => {
          const params = {
            id: row.id,
          };
          const res = await deleteMenuList(params);

          if (res.data.success) {
            this.$messageSuccess("删除成功");
            this.search();
          }
        })
        .catch(() => {});
    },
    submitForm() {
      this.$refs.menuManagementObj.validate(async (valid) => {
        if (valid) {
          let res;
          // 新增
          if (this.dialogType === 0) {
            res = await addMenuList(this.menuManagementObj);
          } else {
            res = await updataMenuList(this.menuManagementObj);
          }

          if (res.data.success) {
            this.$messageSuccess(
              `菜单${this.dialogType ? "编辑" : "新增"}成功`
            );
            this.search();
          }
          this.dialogVisible = false;
        }
      });
    },
    handleClose() {
      this.$refs.menuManagementObj.resetFields();

      Object.assign(this.menuManagementObj, defaultMenuManagementObj);

      this.dialogVisible = false;
    },
    handleTreeSelectChange(data) {
      this.menuManagementObj.parentId = data.id;

      this.menuManagementObj.parentName = data.name;
    },
  },
};
</script>
<style scoped lang='less'>
.el-button_edit {
  color: #65b471;
}
.el-button_del {
  color: #ff4444;
}

/deep/.icon-jr-icon-editt {
  color: #7dafff;
  font-size: 12px;
}
/deep/.icon-jr-icon-delete_1 {
  color: #ffbda9;
  font-size: 12px;
}
</style>