var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuManagement"},[_c('el-form',[_c('el-form-item',[_c('el-button',{staticClass:"el-button_add",attrs:{"type":"primary"},on:{"click":_vm.addMenu}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 新增 ")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.menuManagementTable,"row-key":"id","lazy":"","border":"","header-cell-style":_vm.cellStyle,"height":"652"}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","width":"100","align":"center"}}),_c('TableTreeColumn',{attrs:{"prop":"name","treeKey":"id","label":"名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"icon","label":"图标","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{class:row.icon || ''})]}}])}),_c('el-table-column',{attrs:{"prop":"menuType","label":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.menuType == 'DIRECTORY')?_c('el-tag',{attrs:{"size":"small"}},[_vm._v("目录")]):(row.menuType == 'MENU')?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("菜单")]):(row.menuType == 'RIGHT')?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("按钮")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"parentName","label":"上级菜单","align":"center"}}),_c('el-table-column',{attrs:{"prop":"url","label":"菜单URL","align":"center"}}),_c('el-table-column',{attrs:{"prop":"perms","label":"授权标识","align":"center"}}),_c('el-table-column',{attrs:{"prop":"orderNum","label":"排序","align":"center"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"operation_bottom"},[_c('DicButton',{attrs:{"icon":"icon-jr-icon-editt iconfont","text":"编辑"},on:{"buttonClick":function($event){return _vm.editItem(row)}}}),_c('DicButton',{attrs:{"icon":"icon-jr-icon-delete_1 iconfont","text":"删除"},on:{"buttonClick":function($event){return _vm.deleteItem(row)}}})],1)]}}])})],1),_c('el-dialog',{ref:"addVisible",attrs:{"title":_vm.dialogType ? '编辑菜单' : '新增菜单',"visible":_vm.dialogVisible,"width":"25%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"menuManagementObj",attrs:{"model":_vm.menuManagementObj,"rules":_vm.menuManagementRules,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"菜单类型","prop":"menuType"}},[_c('el-radio-group',{model:{value:(_vm.menuManagementObj.menuType),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "menuType", $$v)},expression:"menuManagementObj.menuType"}},_vm._l((_vm.menuTypeList),function(item){return _c('el-radio',{key:item.value,attrs:{"label":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1),_c('el-form-item',{attrs:{"label":((_vm.menuTypeList[_vm.menuName].label) + "名称"),"prop":"name"}},[_c('el-input',{attrs:{"placeholder":("请输入" + (_vm.menuTypeList[_vm.menuName].label) + "名称")},model:{value:(_vm.menuManagementObj.name),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "name", $$v)},expression:"menuManagementObj.name"}})],1),_c('el-form-item',{attrs:{"label":"上级菜单","prop":"parentName"}},[_c('PopupTreeInput',{attrs:{"data":_vm.popupTreeData,"props":_vm.popupTreeProps,"prop":_vm.menuManagementObj.parentName == null ||
            _vm.menuManagementObj.parentName == ''
              ? '顶级菜单'
              : _vm.menuManagementObj.parentName,"nodeKey":'' + _vm.menuManagementObj.parentId,"currentChangeHandle":_vm.handleTreeSelectChange}})],1),_c('el-form-item',{attrs:{"label":"授权标识","prop":"perms"}},[_c('el-input',{attrs:{"placeholder":"请输入授权标识"},model:{value:(_vm.menuManagementObj.perms),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "perms", $$v)},expression:"menuManagementObj.perms"}})],1),_c('el-form-item',{attrs:{"label":"菜单URL","prop":"url"}},[_c('el-input',{attrs:{"placeholder":"请输入菜单URL"},model:{value:(_vm.menuManagementObj.url),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "url", $$v)},expression:"menuManagementObj.url"}})],1),_c('el-form-item',{attrs:{"label":"排序编号","prop":"orderNum"}},[_c('el-input-number',{attrs:{"controls-position":"right","min":0},model:{value:(_vm.menuManagementObj.orderNum),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "orderNum", $$v)},expression:"menuManagementObj.orderNum"}})],1),_c('el-form-item',{attrs:{"label":"菜单图标","prop":"icon"}},[_c('el-input',{attrs:{"placeholder":"请输入菜单icon"},model:{value:(_vm.menuManagementObj.icon),callback:function ($$v) {_vm.$set(_vm.menuManagementObj, "icon", $$v)},expression:"menuManagementObj.icon"}})],1)],1),_c('div',{staticClass:"text-right"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }