<template>
  <div class="dicButton">
    <el-button type="text" size="middle" @click="buttonClick">
      <i :class="icon" />
      {{ text }}
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
  },

  methods: {
    buttonClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style lang="less" scoped>
.el-button,
.el-button:hover,
.el-button:active {
  color: #333333 !important;
  i {
    font-size: 14px;
  }
  .iconjr-icon-edit {
    color: #7ce0c4;
  }
  .iconjr-icon-editt {
    color: #7dafff;
  }
  .iconjr-icon-delete_1 {
    color: #ffbda9;
  }
  .iconjr-icon-saw{
    color: #4AA9FF;
    font-size: 12px !important;
  }
  .iconjr-icon-people_1{
    color: #FE8E42;
  }
}
</style>